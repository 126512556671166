import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './contratos.css';

const Contratos = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container contratos-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='contratos_title'>
                    <h1>Contratos</h1>
                </div>
            </div>
        </div>

        <div className='row'>

          <div class="accordion" id="accordionExample">

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2024">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2024" aria-expanded="false" aria-controls="collapseOne2024">2024</button>
              </h2>
              <div id="collapseOne2024" class="accordion-collapse collapse" aria-labelledby="headingOne2024" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                    <li class="list-group-item">11069780_1107001_1106918_11070565_11070701_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/11069780_1107001_1106918_11070565_11070701_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_003_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_003_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_004_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_004_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_005_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_005_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_006_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_006_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_007_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_007_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_008_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_008_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_009_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_009_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_010_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_010_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                  
                    <li class="list-group-item">SC_DA_CONTRATO_011_2024_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_011_2024_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_012_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_012_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_013_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_013_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_014_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_014_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_015_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_015_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_019_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_019_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_020_2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC_DA_CONTRATO_020_2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">Contrato_007_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Contrato_007_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a>
                      <ul><li class="list-group-item">Factura_A574F4AA_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Factura_A574F4AA_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li></ul>
                    </li>
                    <li class="list-group-item">Contrato_015_2024_0002<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Contrato_015_2024_0002.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a>
                      <ul><li class="list-group-item">Factura_81967143_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/Factura_81967143_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li></ul>
                    </li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-025-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-025-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-029-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-029-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-030-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-030-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DGAF-CONTRATO-031-2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2024/Adquisiciones/Contratos/SC-DGAF-CONTRATO-031-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2023-octubre">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2023-octubre" aria-expanded="false" aria-controls="collapseOne2023-octubre">2023 | Octubre - Diciembre</button>
              </h2>
              <div id="collapseOne2023-octubre" class="accordion-collapse collapse" aria-labelledby="headingOne2023-octubre" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul class="list-group">
                    <li class="list-group-item">SC_DA_CONTRATO_046_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRETO_046_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_048_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_048_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_049_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_049_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_053_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_053_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_054_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_054_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_056_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_056_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_058_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_058_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_059_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRARO_059_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_060_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_060_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_061_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_061_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_062_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_062_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_063_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_063_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC_DA_CONTRATO_064_2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC_DA_CONTRATO_064_2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-026-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-026-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-028-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-028-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-051-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-051-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-057-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-057-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-058-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-058-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-059-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-059-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-060-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-060-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-061-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-061-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-065-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-065-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-066-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-066-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-067-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-067-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-068-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-068-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-069-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-069-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-072-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-072-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-073-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-073-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-074-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-074-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-078-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-078-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-079-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-079-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-080-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-080-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-081-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-081-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-CONTRATO-116-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-CONTRATO-116-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>

                    <li class="list-group-item">SC-DA-C-PROFEST-01-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-01-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-02-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-02-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-03-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-03-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-05-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-05-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-06-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-06-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-07-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-07-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-08-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-08-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-09-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-09-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-10-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-10-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-11-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-11-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-12-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-12-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-14-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-14-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-15-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-15-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-16-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-16-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-19-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-19-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-20-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-20-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-23-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-23-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-25-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-25-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-29-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-29-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-30-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-30-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-31-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-31-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-32-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-32-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-34-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-34-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-35-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-35-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-36-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-36-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-38-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-38-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    <li class="list-group-item">SC-DA-C-PROFEST-39-2023_0001<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/2023/Adquisiciones/Contratos/SC-DA-C-PROFEST-39-2023_0001.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>
    </div>
  )
}

export default Contratos